<template>
  <div class="lesson-detail-rating">
    <b-container>
      <b-row>
        <b-col>
          <div class="lesson-detail-rating__title">
            <span>Đánh giá: Toàn bộ bài tập</span>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="lesson-detail-rating__rating-linear">
            <div
              class="lesson-detail-rating__rating-linear__item"
              v-for="(rating, index) in rating"
              :key="index"
            >
              <b-row>
                <b-col cols="2">
                  <div
                    :class="`lesson-detail-rating__rating-linear__item__type--${rating.id}`"
                  >
                    <span>{{ rating.label }}</span>
                  </div>
                </b-col>
                <b-col cols="10" class="d-flex align-items-center">
                  <b-progress
                    :class="`lesson-detail-rating__rating-linear__item--${rating.id}`"
                    class="w-75 mr-2"
                    height="5px"
                    :value="rating.value"
                  />
                  <span class="mr-2">{{ rating.value }}%</span>
                  <span class="text-muted">({{ rating.total }} đánh giá)</span>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <div class="lesson-detail-rating__title">
            <span>Đánh giá và nhận xét</span>
          </div>
        </b-col>
        <b-col>
          <div class="lesson-detail-rating__comments">
            <b-avatar class="mr-4" rounded size="40" />
            <div>
              <div class="lesson-detail-rating__comments__username mb-1">
                Tên người dùng
              </div>
              <div class="lesson-detail-rating__comments__type text-muted mb-1">
                Gói thấu cảm
              </div>
              <div class="lesson-detail-rating__comments__rating mb-1">
                <v-rating
                  class="mr-2"
                  dense
                  readonly
                  :value="4"
                  size="16"
                  color="#407bff"
                />
                <span
                  class="text-muted lesson-detail-rating__comments__rating__date"
                  >06/10/2021 08:15</span
                >
              </div>
              <div class="lesson-detail-rating__comments__content">
                Rất bổ ích cho người bị bệnh tiểu đường như tôi
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: 'MovementRouteLessonReview',
  data() {
    return {
      rating: [
        {
          id: 5,
          label: 'Bài tập quá nhẹ',
          value: 42.8,
          total: 45,
        },
        {
          id: 4,
          label: 'Bài tập nhẹ',
          value: 28.5,
          total: 30,
        },
        {
          id: 3,
          label: 'Bài tập vừa sức',
          value: 14.2,
          total: 15,
        },
        {
          id: 2,
          label: 'Bài tập nặng',
          value: 9.5,
          total: 10,
        },
        {
          id: 1,
          label: 'Bài tập quá nặng',
          value: 4.7,
          total: 5,
        },
      ],
      comments: [
        {
          avatar: '',
          name: '',
          rating: 5,
          time: '04/10/2021',
          content: 'Test',
        },
      ],
      paging: {
        page: 1,
        pageSize: 10,
        total: 100,
      },
    };
  },
};
</script>

<style lang="scss">
.lesson-detail-rating {
  .lesson-detail-rating__title {
    font-weight: bold;
    font-size: 16px;
  }

  .lesson-detail-rating__rating-progress {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .lesson-detail-rating__rating-linear {
    .lesson-detail-rating__rating-linear__item {
      display: flex;
      align-items: center;
      margin-bottom: 8px;

      .lesson-detail-rating__rating-linear__item__type--1 {
        color: #e53935;
      }

      .lesson-detail-rating__rating-linear__item__type--2 {
        color: #f2c94c;
      }

      .lesson-detail-rating__rating-linear__item__type--3 {
        color: #f58220;
      }

      .lesson-detail-rating__rating-linear__item__type--4 {
        color: #407bff;
      }

      .lesson-detail-rating__rating-linear__item__type--5 {
        color: #4bb2ab;
      }

      .lesson-detail-rating__rating-linear__item--1 {
        .progress-bar {
          background-color: #e53935;
        }
      }

      .lesson-detail-rating__rating-linear__item--2 {
        .progress-bar {
          background-color: #f2c94c;
        }
      }

      .lesson-detail-rating__rating-linear__item--3 {
        .progress-bar {
          background-color: #f58220;
        }
      }

      .lesson-detail-rating__rating-linear__item--4 {
        .progress-bar {
          background-color: #407bff;
        }
      }

      .lesson-detail-rating__rating-linear__item--5 {
        .progress-bar {
          background-color: #4bb2ab;
        }
      }
    }
  }

  .lesson-detail-rating__comments {
    padding: 0 20px;
    display: flex;
    margin-bottom: 20px;

    .lesson-detail-rating__comments__username {
      font-weight: bold;
    }

    .lesson-detail-rating__comments__type {
      font-size: 12px;
    }

    .lesson-detail-rating__comments__rating {
      display: flex;
      align-items: center;

      .lesson-detail-rating__comments__rating__date {
        align-self: flex-end;
        font-size: 10px;
      }
    }
  }
}
</style>
